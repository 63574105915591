import React from 'react';
import styled from 'styled-components';
import { SectionTitle } from 'components/section-title';
import { SectionWrapper } from 'components/section-wrapper';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SContainer = styled.div`
  background: ${COLORS.LIGHT_GREY};
  padding: 3.5rem;
  padding-top: 3.75rem;

  ${MEDIA.max768`
     padding: 2.5rem;
  padding-top: 2.75rem;
`}
`;

const SGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 3.5rem;
  margin-top: 2rem;

  ${MEDIA.max768`
     grid-template-columns:  1fr;
`}
`;

const SParagraph = styled.p`
  color: ${COLORS.GREY};
  font-size: 1.125rem;

  & span {
    font-weight: bold;
  }
`;

export const AestheticMedicine = () => {
  return (
    <SectionWrapper>
      <SContainer>
        <SectionTitle title="Czym jest medycyna estetyczna?" />
        <SGrid>
          <SParagraph>
            Zabiegi medycyny estetycznej w IDEALE, skutecznie poprawiają wygląd
            oraz kondycję skóry, wykorzystując sprawdzone metody takie jak
            botox, wypełnienia kwasem hialuronowym, zabiegi ze stymulatorami
            tkankowymi oraz mezoterapię. Każdy z tych zabiegów jest
            przeprowadzany z największą dbałością o detale i bezpieczeństwo
            pacjenta, co gwarantuje najwyższej jakości usługi.
          </SParagraph>
          <SParagraph>
            <span>Stymulatory tkankowe</span> to innowacyjna metoda regeneracji
            i odnowy skóry, która stymuluje naturalne procesy naprawcze w
            organizmie, co prowadzi do poprawy jakości skóry i zwiększenia jej
            jędrności. Z kolei <span>mezoterapia</span> to technika polegająca
            na mikroiniekcjach, które dostarczają skórze niezbędnych składników
            odżywczych, witamin oraz antyoksydantów, intensywnie ją
            rewitalizując i odmładzając.
          </SParagraph>
          <SParagraph>
            <span>Botox</span> to jeden z najbardziej popularnych zabiegów
            medycyny estetycznej, który pozwala na wygładzenie zmarszczek
            mimicznych, zapewniając młodszy i bardziej wypoczęty wygląd twarzy.
            Zabiegi z <span>kwasem hialuronowym</span> to doskonały sposób na
            przywrócenie skórze jędrności, nawilżenia i wolumenu, dzięki czemu
            twarz odzyskuje swoje młodzieńcze kontury.
          </SParagraph>
          <SParagraph>
            Gabinet medycyny estetycznej IDEALE w Warszawie, korzystając z
            najnowszych osiągnięć i technologii, oferuje te wyselekcjonowane i
            skuteczne zabiegi, aby każdy pacjent mógł cieszyć się zdrowym,
            młodym wyglądem oraz wysoką jakością życia. IDEALE to miejsce, gdzie
            zaawansowana medycyna estetyczna spotyka się z indywidualnym
            podejściem do potrzeb i oczekiwań klientów, zapewniając najlepsze
            efekty i pełne zadowolenie.
          </SParagraph>
        </SGrid>
      </SContainer>
    </SectionWrapper>
  );
};
