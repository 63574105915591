import React from 'react';
import styled from 'styled-components';
import { NonStretchedImage } from 'components/non-stretched-image';
import { SectionWrapper } from 'components/section-wrapper';
import { graphql, useStaticQuery } from 'gatsby';
import MEDIA from 'styles/media';

const SSection = styled(SectionWrapper)`
  margin-top: 15rem;

  ${MEDIA.max1024`
  margin-top: 8rem;
`}

  ${MEDIA.max768`
  width: 100%;
  padding-left: 4%;
`}
`;

const SImage = styled(NonStretchedImage)`
  max-width: 48.125rem;
  display: block;
  ${MEDIA.max768`
        display: none;
    `}
`;

const SMobileImage = styled(NonStretchedImage)`
  max-width: 27.375rem;
  display: none;

  ${MEDIA.max768`
    display: block;
`}
`;

const SWrapper = styled.div`
  display: flex;
`;

const STextColumn = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  justify-content: center;
  gap: 0.5rem;
`;

const STitle = styled.h1`
  font-weight: bold;
  font-size: 7rem;
  font-family: 'Abhaya Libre', serif;
  line-height: 1;
  z-index: 1;
  white-space: nowrap;
  word-wrap: normal;
  margin-bottom: 0;
  ${MEDIA.max1600`
      font-size: 6.5rem;
    `}
  ${MEDIA.max1024`
      font-size: 5.5rem;
      white-space: unset;
    `}
  ${MEDIA.max520`
      font-size: 3.5rem;
  `}
`;

export const MainSection = () => {
  const data = useStaticQuery(graphql`
    query {
      treatmentsMain: file(relativePath: { eq: "treatments/main.png" }) {
        childImageSharp {
          gatsbyImageData(width: 770, quality: 100, formats: [WEBP])
        }
      }
      treatmentsMobile: file(
        relativePath: { eq: "treatments/main-mobile.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 438, quality: 100, formats: [WEBP])
        }
      }
    }
  `);

  return (
    <SSection>
      <SWrapper>
        <STextColumn>
          <STitle>
            Oferta <span>zabiegów</span>
          </STitle>
          <STitle>medycyny</STitle>
          <STitle>estetycznej</STitle>
        </STextColumn>
        <SImage
          childImageSharp={data.treatmentsMain.childImageSharp}
          alt="ideale medycyna estetyczna warszawa wola aesthetic medicine"
        />
        <SMobileImage childImageSharp={data.treatmentsMobile.childImageSharp} />
      </SWrapper>
    </SSection>
  );
};
