import React from 'react';
import styled, { css } from 'styled-components';
import { LinkButton } from 'components/link-button';
import { NonStretchedImage } from 'components/non-stretched-image';
import { SectionTitle } from 'components/section-title';
import { SectionWrapper } from 'components/section-wrapper';
import { PATHS } from 'constants/paths';
import { array, bool, object, string } from 'prop-types';
import { COLORS } from 'styles/constants/colors';
import MEDIA from 'styles/media';

const SContainer = styled.div`
  display: flex;
  flex-direction: ${({ inverted }) => (inverted ? 'row-reverse' : 'row')};
  justify-content: space-between;
  margin-top: 6rem;

  ${MEDIA.max768`
    flex-direction: column;
    gap: 2rem;
`};
`;

const STextColumn = styled.div`
  width: 50%;

  ${MEDIA.max768`
   width: 100%;
`};
`;

const SParagraph = styled.p`
  color: ${COLORS.GREY};
  font-size: ${({ isSmall }) => (isSmall ? '1rem' : '1.125rem')};
`;

const SPopularOffersText = styled.p`
  font-weight: bold;
  font-size: 1rem;
  text-transform: uppercase;
  margin-bottom: 1rem;
  margin-top: 2rem;
`;

const SAllOffersLink = styled(LinkButton)`
  margin-top: 2rem;
  margin-bottom: 2.5rem;
  width: auto;
  max-width: 21.25rem;
  box-shadow: 0px 10px 22px #0000001f;
  text-transform: uppercase;
  font-weight: 600;
`;

const SOffersButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2.5rem;
`;

const SOfferLink = styled.a`
  width: auto;
  color: ${COLORS.BLACK};
  text-align: center;
  font-size: 1.5rem;
  border: 2px solid ${COLORS.CREAMY};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  text-decoration: none;
  font-family: 'Abhaya Libre', serif;
  font-weight: bold;
  padding: 0.5rem 2.5rem;
`;

const SImageColumn = styled.div`
  width: 45%;

  ${MEDIA.max768`
    width: 100%;
 `};
`;

const SImageBackground = styled.div`
  position: relative;
  max-height: 805rem;
  display: flex;
  align-items: center;
  padding: 60px 0;
  justify-content: end;

  &::before {
    content: '';
    position: absolute;
    background: ${COLORS.LIGHT_GREY};
    top: 0;
    bottom: 0;
    width: 110%;
    ${({ inverted }) =>
      inverted
        ? css`
            right: -12rem;
          `
        : css`
            left: -12rem;
          `};

    ${MEDIA.max768`
      width: 100%;
            ${({ inverted }) =>
              inverted
                ? css`
                    right: -12%;
                  `
                : css`
                    left: -12%;
                  `};
        `};
  }
`;

const SImage = styled(NonStretchedImage)`
  max-height: 470px;
  max-width: 640px;
`;

const SSectionTitle = styled(SectionTitle)`
  margin-top: 0;
  text-align: left;
`;

export const TreatmentSection = ({
  title,
  descriptions,
  popularTreatments,
  url,
  image,
  inverted,
  popularOffersText,
  imageAlt,
}) => {
  return (
    <SectionWrapper>
      <SContainer inverted={inverted}>
        <SImageColumn>
          <SImageBackground inverted={inverted}>
            <SImage childImageSharp={image} alt={imageAlt} />
          </SImageBackground>
        </SImageColumn>
        <STextColumn>
          <SSectionTitle title={title} />
          <SParagraph>{descriptions[0]}</SParagraph>
          <SPopularOffersText>
            Najpopularniejsze zabiegi {popularOffersText}
          </SPopularOffersText>
          <SOffersButtons>
            {popularTreatments.map((item) => (
              <SOfferLink
                key={item.text}
                target="_blank"
                href={`/oferta/${item.href}`}
              >
                {item.text}
              </SOfferLink>
            ))}
          </SOffersButtons>
          <SParagraph isSmall>{descriptions[1]}</SParagraph>
          <SAllOffersLink
            to={`${PATHS.OFFER}${url}`}
            text="Sprawdź pełen zakres zabiegów"
            full
          />
        </STextColumn>
      </SContainer>
    </SectionWrapper>
  );
};

TreatmentSection.propTypes = {
  title: string.isRequired,
  descriptions: string.isRequired,
  popularTreatments: array.isRequired,
  url: string.isRequired,
  image: object.isRequired,
  inverted: bool,
  popularOffersText: string.isRequired,
  imageAlt: string.isRequired,
};
