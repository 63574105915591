import React from 'react';
import styled from 'styled-components';
import { node, string } from 'prop-types';
import MEDIA from 'styles/media';

const SSectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SSectionWrapper = styled.div`
  min-height: 30vh;
  width: 80%;
  margin-bottom: 5rem;
  ${MEDIA.max1024`
        margin-bottom: 3rem;
    `}
`;

export const SectionWrapper = (props) => {
  const { children, className, id } = props;
  return (
    <SSectionContainer>
      <SSectionWrapper className={className} id={id}>
        {children}
      </SSectionWrapper>
    </SSectionContainer>
  );
};

SectionWrapper.defaultProps = {
  className: '',
};

SectionWrapper.propTypes = {
  children: node.isRequired,
  id: string.isRequired,
  className: string,
};
